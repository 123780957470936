import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Logo from '../images/logo.png'

const MenuList = styled.ul`
  float: right;
  padding: 0;
  list-style-type: none;
  height: 100px;
  overflow: visible;
  margin: 0;
  @media (max-width: 850px) {
    display: none;
  }
`

const MenuElement = styled.li`
  display: inline-block;
  line-height: 120px;
  padding: 0 20px;
`

const Header = () => (
  <header
    style={{
      background: `#fff`,
      marginBottom: `1.45rem`,
      position: 'fixed',
      zIndex: 1000,
      top: 0,
      left: 0,
      width: '100%',
      height: '100px',
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1080,
        height: '100%',
      }}
    >
      <Link
        to="/"
        style={{
          textDecoration: `none`,
        }}
      >
        <img
          style={{
            height: '35px',
            marginTop: '40px',
          }}
          src={Logo}
          alt="Logo educamed"
        />
      </Link>
      <MenuList>
        <MenuElement>
          <Link
            to="/"
            style={{
              textDecoration: `none`,
              color: '#3515A8',
              fontWeight: 500,
            }}
          >
            Inicio
          </Link>
        </MenuElement>
        <MenuElement>
          <Link
            to="/#nosotros"
            style={{
              textDecoration: `none`,
              color: '#3515A8',
              fontWeight: 500,
            }}
          >
            Nosotros
          </Link>
        </MenuElement>
        <MenuElement>
          <Link
            to="/#servicios"
            style={{
              textDecoration: `none`,
              color: '#3515A8',
              fontWeight: 500,
            }}
          >
            Servicios
          </Link>
        </MenuElement>
        <MenuElement>
          <Link
            to="/#contacto"
            style={{
              textDecoration: `none`,
              color: '#3515A8',
              fontWeight: 500,
            }}
          >
            Contacto
          </Link>
        </MenuElement>
      </MenuList>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
