import React, {useRef, useState} from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import Grid from 'hedron'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group' // ES6
import scrollToComponent from 'react-scroll-to-component'

import Layout from '../components/layout'
import SEO from '../components/seo'

import home from '../images/home.png'
import homeBg from '../images/bg-home.jpg'
import services1 from '../images/services-1.jpg'
import services2 from '../images/services-2.jpg'
import services3 from '../images/services-3.jpg'
import services4 from '../images/services-4.jpg'
import clynicalAnalysis from '../images/clynical-analysis.png'
import bioestadistic from '../images/bioestadistic.png'
import strategies from '../images/estrategies.png'
import stakeholders from '../images/stakeholders.png'
import consultory from '../images/consultory.png'
import education from '../images/continuous-education.png'
import marketResearch from '../images/market-research.png'
import marketingPlan from '../images/marketing-plan.png'
import marketingDigital from '../images/digital-marketing.png'
import publicity from '../images/publicity.png'
import development from '../images/gestion.png'
import sellsForce from '../images/sell-force.png'
import care from '../images/care.svg'
import presentation from '../images/presentation.svg'
import money from '../images/money.svg'
import openBook from '../images/open-book.svg'
import whistle from '../images/whistle.png'
import airplaneChairs from '../images/airplane-chairs.png'
import projectorScreen from '../images/projector-screen.png'
import LazyLoad from 'react-lazyload'

const Section = styled.div`
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 10px 20px;
  padding-top: 100px;
`

const H1 = styled.h1`
  text-transform: uppercase;
  font-weight: 600;
  color: #282723;
  text-align: center;
  font-size: 48px;
  margin-bottom: 20px;
`

const H2 = styled.h2`
  text-transform: uppercase;
  font-weight: 600;
  color: #3533ab;
  text-align: center;
  font-size: 48px;
  margin: 30px 0;
`

/** HOME */

const Home = styled(Section)`
  min-height: 45vh;
  padding-top: 10px;
  background: url(${homeBg}) no-repeat center center;
  background-size: cover;
  @media (max-width: 850px) {
    min-height: unset;
  }
`

const HomeTextContainer = styled.div`
  width: 45%;
  margin-left: 55%;
  color: #fff;
  margin-top: 20px;
  box-sixing: border-box;
  padding: 20px;
  padding-bottom: 40px;
  text-align: right;
  @media (max-width: 950px) {
    width: 52%;
    margin-left: 48%;
  }
  @media (max-width: 850px) {
    width: 100%;
    margin-left: 0;
    text-align: center;
    padding: 10px;
    padding-bottom: 60px;
  }
`

const HomeH1 = styled.h1`
  font-weight: 500;
  font-size: 30px;
  @media (max-width: 850px) {
    font-size: 38px;
  }
`

const HomeP = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin: 20px 0px;
  width: 80%;
  margin-left: 20%;
  @media (max-width: 850px) {
    width: 100%;
    margin-left: 0;
    font-size: 16px;
  }
`

const HomeButton = styled(Link)`
  background: #fe4e85;
  border: none;
  box-shadow: none;
  border-radius: 15px;
  font-size: 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  padding: 20px 30px;
  font-weight: 600;
`

const HomeImage = styled.img`
  position: absolute;
  left: 40px;
  top: 40px;
  width: 62%;
  @media (max-width: 850px) {
    display: none;
  }
  @media (max-width: 950px) {
    width: 55%;
  }
`

/** About us */
const UsCard = styled.div`
  border-radius: 30px;
  border: 1px solid #707070;
  padding: 20px 20px;
  text-align: center;
  width: calc(100% - 60px);
  margin: 0 30px;
  height: 100%;
`

const UsH1 = styled.h1`
  text-transform: uppercase;
  color: #34bdc3;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
`

const UsText = styled.p`
  color: #919191;
  font-size: 14px;
  font-weight: 500;
  align-self: center;
`

const UsBox = ({children}) => (
  <Grid.Box
    mobile={{width: '100%'}}
    tablet={{width: '100%'}}
    wide={{width: '33.3333333%'}}
  >
    {children}
  </Grid.Box>
)

/* Services */
const ServicesBox = ({children, style, onClick}) => (
  <Grid.Box
    fluid
    mobile={{width: '100%', height: '300px'}}
    tablet={{width: '50%', height: '300px'}}
    wide={{width: '25%', height: '300px'}}
    style={{
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      backgroundSize: 'cover',
      position: 'relative',
      cursor: 'pointer',
      ...style,
    }}
    onClick={onClick}
  >
    {children}
  </Grid.Box>
)

const ServicesBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
`

/* Marketing  and Medical Affairs*/
const StyledMarketingTab = styled(Grid.Box)`
  cursor: pointer;
  color: #919191;
  background: #ffffff;
  border: 2px solid #ebebeb;
  border-left: none;
  box-sizing: border-box;
  height: 130px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 20px;
  &.active {
    color: #ffffff;
    background: #3231a4;
  }
`

const StyledMedicalAffairsTab = styled(StyledMarketingTab)`
  border-left: 2px solid #ebebeb;
  border-top: none;
  border-right: none;
  height: 16.6666%;
  @media (max-width: 850px) {
    height: 60px;
    border-right: 2px solid #ebebeb;
    &:nth-of-type(2) {
      border-top: 2px solid #ebebeb;
      border-left: none;
    }
    &:nth-of-type(4),
    &:nth-of-type(6) {
      border-left: none;
    }
    &:nth-of-type(5),
    &:nth-of-type(6) {
      border-bottom: none;
    }
  }
`

const MedicalAffairsTab = ({children, style, onClick, className}) => (
  <StyledMedicalAffairsTab
    fluid
    mobile={{width: '50%'}}
    tablet={{width: '50%'}}
    wide={{width: '100%', height: '16.6666%'}}
    style={{
      ...style,
    }}
    className={className}
    onClick={onClick}
  >
    {children}
  </StyledMedicalAffairsTab>
)

const MarketingText = styled.p`
  color: #b6b6b6;
  margin: 0;
  marginbottom: 3px;
  font-size: 17px;
  font-weight: 400;
`

const MarketingTextTitle = styled(MarketingText)`
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 40px;
`

const MarketingTab = ({children, style, onClick, className}) => (
  <StyledMarketingTab
    fluid
    mobile={{width: '50%'}}
    tablet={{width: '33.33333%'}}
    wide={{width: '16.6666%'}}
    style={{
      ...style,
    }}
    className={className}
    onClick={onClick}
  >
    {children}
  </StyledMarketingTab>
)

const medicalAffairsData = [
  {
    titleH4: 'Estudios',
    titleH1: 'Clínicos',
    content: () => (
      <React.Fragment>
        <MarketingText>
          <b>Fases clínicas I-III.</b> Proyectos pequeños hasta multinacionales.
        </MarketingText>
        <MarketingText>
          <b>Fases tardías de post-aprobación.</b>
        </MarketingText>
        <MarketingText>
          <b>Fase IV.</b> Análisis de bases de datos, registros, encuestas
          epidemiológicas.
        </MarketingText>
        <MarketingText>
          <b>Publicaciones de evidencia de mundo real.</b>
        </MarketingText>
        <MarketingText style={{marginTop: '20px'}}>
          Editamos y revisamos <b>textos médicos</b>
        </MarketingText>
        <MarketingText>
          Generación de datos y análisis estadísticos
        </MarketingText>
        <MarketingText>Cálculos muestrales</MarketingText>
        <MarketingText>
          <b>Publicaciones</b> en revistas
        </MarketingText>
      </React.Fragment>
    ),
    image: clynicalAnalysis,
  },
  {
    titleH4: '',
    titleH1: 'Bioestadística',
    content: () => (
      <React.Fragment>
        <MarketingText>
          Análisis multivariables, árboles de decisión, PK/PD y otros.
        </MarketingText>
        <MarketingText>
          Análisis <b>epidemiológicos</b> post-aprobación, verificación, control
          de calidad y más.
        </MarketingText>
        <MarketingText style={{marginTop: '20px'}}>
          Desarrollo de macros SAS
        </MarketingText>
        <MarketingText style={{marginTop: '20px'}}>
          Mapeo<b> CDISC SDTM & ADAM</b>
        </MarketingText>
      </React.Fragment>
    ),
    image: bioestadistic,
  },
  {
    titleH4: '',
    titleH1: 'Estrategias',
    content: () => (
      <React.Fragment>
        <MarketingText>
          <b>Estrategias Estándar:</b>
        </MarketingText>
        <MarketingText style={{marginTop: '20px'}}>
          Plan estratégico de medical, desarrollo de slides corporativas
        </MarketingText>
        <MarketingText>
          Entrenamiento a ponentes asociados, consejo de asociados, reuniones de
          expertos.{' '}
        </MarketingText>
        <MarketingText>
          Congresos, simposiums, eventos organizados por los socios.
        </MarketingText>
        <MarketingText style={{marginTop: '20px'}}>
          <b>Estrategias de Negocio Científicas:</b>
        </MarketingText>
        <MarketingText style={{marginTop: '20px'}}>
          Modelos de atención.
        </MarketingText>
        <MarketingText>
          Enlaces médicos científicos virtuales y outsourcing.
        </MarketingText>
        <MarketingText>
          Manejo de relaciones con lideres de opinión.
        </MarketingText>
        <MarketingText>Desarrollo de software.</MarketingText>
      </React.Fragment>
    ),
    image: strategies,
  },
  {
    titleH4: 'Mapeo de',
    titleH1: 'Stakeholders',
    content: () => (
      <React.Fragment>
        <MarketingText>
          Profesionales de la salud, sociedades de médicos, pacientes y
          pagadores, nosotros mapeamos el camino de interacción y las posibles
          barreras para tus productos y soluciones.
        </MarketingText>
        <MarketingText style={{marginTop: '20px'}}>
          Inclusión en sistemas de salud, conocemos las formas y a las personas,
          y si no…
        </MarketingText>
        <MarketingText style={{marginTop: '20px'}}>
          <b>¡lo haremos!.</b>
        </MarketingText>
      </React.Fragment>
    ),
    image: stakeholders,
  },
  {
    titleH4: '',
    titleH1: 'Consultoría',
    content: () => (
      <React.Fragment>
        <MarketingText>
          Un Proyecto viable con resultados sobresalientes que hacen la
          diferencia, nosotros evaluamos la oportunidad de acuerdo a tu visión y
          recursos.
        </MarketingText>
        <MarketingText style={{marginTop: '20px'}}>
          Nuestro equipo cuenta con experiencia en el área terapéutica, con alto
          nivel científico y entrenamiento.
        </MarketingText>
      </React.Fragment>
    ),
    image: consultory,
  },
  {
    titleH4: 'Educación',
    titleH1: 'Continua',
    content: () => (
      <React.Fragment>
        <MarketingText>
          <b>Creamos y promovemos:</b>
        </MarketingText>
        <MarketingText style={{marginTop: '20px'}}>
          Programas de educación para profesionales de la salud
        </MarketingText>
        <MarketingText>
          Creación de contenidos, promoción de iniciativas
        </MarketingText>
        <MarketingText>Portales de educación</MarketingText>
        <MarketingText>Programas de certificación</MarketingText>
        <MarketingText>Avales de asociaciones y sociedades</MarketingText>
      </React.Fragment>
    ),
    image: education,
  },
]

const marketingData = [
  {
    titleH4: 'Investigaciones de',
    titleH1: 'Mercado',
    content: () => (
      <React.Fragment>
        <MarketingTextTitle>Socio estratégico</MarketingTextTitle>
        <MarketingText>
          Integramos tu plan de marketing en las necesidades de los jugadores
          clave
        </MarketingText>
        <MarketingText>
          Focus groups para pacientes, profesionales de la salud y puntos de
          venta
        </MarketingText>
        <MarketingText>
          Descripción de los escenarios actuales y visión de los mismos en el
          futuro
        </MarketingText>
        <MarketingText>
          Evaluación de proyectos piloto y prueba de productos y servicios
        </MarketingText>
        <MarketingText>
          Imagen de marca de acuerdo a las necesidades del consumidor
        </MarketingText>
        <MarketingText>
          Conocimiento de los momentos de la verdad, los canales de decisión,
          necesidades no cubiertas.
        </MarketingText>
      </React.Fragment>
    ),
    image: marketResearch,
  },
  {
    titleH4: 'Desarrollo de',
    titleH1: 'Marketing Plan',
    content: () => (
      <React.Fragment>
        <MarketingTextTitle style={{marginBottom: '5px', marginTop: '20px'}}>
          Panorama del Mercado
        </MarketingTextTitle>
        <MarketingText style={{paddingLeft: '15px'}}>
          Panorama de la enfermedad, modelos transaccionales.
        </MarketingText>
        <MarketingText style={{paddingLeft: '15px'}}>
          Perfil de producto y posicionamiento (impacto económico)
        </MarketingText>
        <MarketingTextTitle style={{marginBottom: '5px', marginTop: '20px'}}>
          Perfil de competidores
        </MarketingTextTitle>
        <MarketingText style={{paddingLeft: '15px'}}>
          Análisis del mercado; tendencias y planes.
        </MarketingText>
        <MarketingText style={{paddingLeft: '15px'}}>
          Capacidades clave por jugador clave (internos & externos)
        </MarketingText>
        <MarketingTextTitle style={{marginBottom: '5px', marginTop: '20px'}}>
          Análisis FODA
        </MarketingTextTitle>
        <MarketingText style={{paddingLeft: '15px'}}>
          Plan de acción por jugador clave KPI´s clave
        </MarketingText>
        <MarketingText style={{paddingLeft: '15px'}}>
          Presupuesto, implementación y panorama del tiempo
        </MarketingText>
      </React.Fragment>
    ),
    image: marketingPlan,
  },
  {
    titleH4: 'Estrategias de',
    titleH1: 'Marketing Digital',
    content: () => (
      <React.Fragment>
        <MarketingText>Desarrollo de e- Commerce</MarketingText>
        <MarketingText>
          Gestión de e- Commerce (Community Manager) Webcast
        </MarketingText>
        <MarketingText>e- Mailing & e- Detailing</MarketingText>
        <MarketingText>Sitios web (web & móvil)</MarketingText>
        <MarketingText>e- Learning</MarketingText>
        <MarketingText>Portales electrónicos para pacientes</MarketingText>
        <MarketingText>
          Portales electrónicos para profesionales de la salud.
        </MarketingText>
        <MarketingText>
          Portal electrónico para educación médica continua
        </MarketingText>
        <MarketingText>Desarrollo de estrategias digitales</MarketingText>
        <MarketingText>SEM/ SEO</MarketingText>
        <MarketingText>Redes Sociales</MarketingText>
        <MarketingText>Desarrollo de aplicaciones.</MarketingText>
      </React.Fragment>
    ),
    image: marketingDigital,
  },
  {
    titleH4: '',
    titleH1: 'Publicidad',
    textTitle: 'Socio estratégico',
    content: () => (
      <React.Fragment>
        <MarketingText>Marketing Relacional.</MarketingText>
        <MarketingText>Planes de comunicación de marketing</MarketingText>
        <MarketingText>Posicionamiento, orientación y promoción</MarketingText>
        <MarketingText>Campañas y desarrollo de materiales</MarketingText>
        <MarketingText>Campañas directas al consumidor</MarketingText>
        <MarketingText>Campañas de productos OTC</MarketingText>
        <MarketingText>Diseño de marca y nombre</MarketingText>
        <MarketingText>Diseño de empaque</MarketingText>
        <MarketingText>Awareness en campañas de marketing</MarketingText>
        <MarketingText>Campañas científicas</MarketingText>
      </React.Fragment>
    ),
    image: publicity,
  },
  {
    titleH4: 'Desarrollo y Gestión',
    titleH1: 'de Contenidos',
    content: () => (
      <React.Fragment>
        <MarketingText>
          Cuando hablamos sobre la creación y el desarrollo,
        </MarketingText>
        <MarketingText>
          Educamed tiene un concepto integrado de gestión de contenido.
        </MarketingText>
        <MarketingText style={{marginTop: '30px'}}>
          Gestión de contenido para sitios web, redes sociales y materiales
        </MarketingText>
        <MarketingText>
          Pruebas del mundo real, bases de datos y programas de registro
        </MarketingText>
        <MarketingText>Cursos de gestión multidisciplinaria</MarketingText>
        <MarketingText>
          Planificación y ejecución de publicaciones
        </MarketingText>
        <MarketingText>Exposición itinerante o independiente</MarketingText>
        <MarketingText>E-learning</MarketingText>
        <MarketingText>Gestión de líderes de opinión</MarketingText>
        <MarketingText>Desarrollo de contenido científico</MarketingText>
        <MarketingText>Creadores de contenido nativo</MarketingText>
      </React.Fragment>
    ),
    image: development,
  },
  {
    titleH4: 'Fuerza de',
    titleH1: 'Ventas',
    content: () => (
      <React.Fragment>
        <MarketingText>
          Entendemos la importancia de la implementación
        </MarketingText>
        <MarketingText>
          y por esa razón ofrecemos una comunicación
        </MarketingText>
        <MarketingText>
          de soporte de ventas con diferentes actividades:
        </MarketingText>
        <MarketingText style={{marginTop: '30px'}}>
          Creación de ayudas visuales e interactivas de ventas, con la
        </MarketingText>
        <MarketingText>estructura promocional respectiva</MarketingText>
        <MarketingText>
          “Role Play” con profesionales de la salud reales.
        </MarketingText>
        <MarketingText>Casos del mundo real</MarketingText>
        <MarketingText>
          Certificación de la estrategia de comunicación
        </MarketingText>
      </React.Fragment>
    ),
    image: sellsForce,
  },
]

/* Market access Sercices */
const MarketAccessCircle = styled.div`
  background: #2aa9ae;
  width: 100%;
  border-radius: 200px;
  position: relative;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const MarketAccessTitle = styled.h4`
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 22px;
`

const MarketAccessText = styled.p`
  margin: 0;
  font-size: 14px;
`

const MarketAccessImg = ({src}) => (
  <Grid.Box
    mobile={{width: '100%'}}
    tablet={{width: '100%'}}
    wide={{width: '20%'}}
    style={{display: 'flex', color: '#ffffff'}}
  >
    <MarketAccessCircle>
      <LazyLoad once height={50}>
        <img
          src={src}
          style={{
            position: 'absolute',
            width: '60%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            marginBottom: 0,
          }}
          alt="Market access"
        />
      </LazyLoad>
    </MarketAccessCircle>
  </Grid.Box>
)

/* Other Services */
const ServicesOtherImage = ({src}) => (
  <Grid.Box
    mobile={{width: '100%'}}
    tablet={{width: '100%'}}
    wide={{width: '45%'}}
    style={{marginTop: '-60px'}}
  >
    <LazyLoad once height={460}>
      <img src={src} alt="Services other" />
    </LazyLoad>
  </Grid.Box>
)

const ServicesOtherH4 = styled.h4`
  font-weight: 400;
  font-size: 25px;
  margin-bottom: 0;
`

const ServicesOtherH1 = styled.h1`
  font-weight: 800;
  font-size: 50px;
  margin-top: 10px;
`

const ServicesOtherP = styled.p`
  margin-bottom: 5px;
  color: #b6b6b6;
  font-size: 16px;
  font-weight: 500;
`

const ServicesOtherContent = ({h1, h4, text, hidden, show}) => (
  <Grid.Box
    mobile={{width: '100%', hidden}}
    tablet={{width: '100%', hidden}}
    wide={{width: '55%', hidden: show}}
  >
    <div style={{width: '100%', height: '100%', display: 'flex'}}>
      <div style={{alignSelf: 'center', width: '100%'}}>
        <ServicesOtherH4 style={{textAlign: hidden ? 'right' : 'left'}}>
          {h4}
        </ServicesOtherH4>
        <ServicesOtherH1 style={{textAlign: hidden ? 'right' : 'left'}}>
          {h1}
        </ServicesOtherH1>
        {text.map((t, index) => (
          <ServicesOtherP
            key={index}
            style={{textAlign: hidden ? 'right' : 'left'}}
          >
            {t}
          </ServicesOtherP>
        ))}
      </div>
    </div>
  </Grid.Box>
)

/* Contact */
const ContactLabel = styled.label`
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
`

const ContactInput = styled.input`
  width: 100%;
  height: 90px;
  font-size: 18px;
  padding: 0px 10px;
  border: 2px solid #d6d4d8;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 30px;
`

const ContactButton = styled.button`
  border-radius: 50px;
  text-transform: uppercase;
  color: #ffffff;
  background: #252525;
  box-shadow: none;
  border: none;
  padding: 20px 35px;
  margin-top: 30px;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 18px;
  &:hover {
    opacity: 0.7;
  }
`

const IndexPage = () => {
  const medicalAffairs = useRef(null) // Hook to ref object
  const marketing = useRef(null)
  const marketAccess = useRef(null)
  const other = useRef(null)
  const [selectedMarketing, setSelectedMarketing] = useState(0)
  const [selectedMedicalAffairs, setSelectedMedicalAffairs] = useState(0)

  const scrollToRef = ref => {
    scrollToComponent(ref.current, {offset: 300})
  }

  return (
    <Layout>
      <SEO title="Inicio" />
      <Home>
        <Grid.Bounds direction="vertical" wide={{direction: 'horizontal'}}>
          <Grid.Box mobile={{hidden: true}} tablet={{hidden: true}}>
            <HomeImage src={home} />
          </Grid.Box>
        </Grid.Bounds>
        <HomeTextContainer>
          <HomeH1>Un mundo de posibilidades a nuestro lado.</HomeH1>
          <HomeP>
            Nuestros servicios te permitirán gestionar tu trabajo de una forma
            más sencilla.
          </HomeP>
          <HomeP>
            Creamos y gestionamos los proyectos la diversas áreas de la
            Industria Farmacéutica.
          </HomeP>
          <HomeP>
            Ofrecemos soporte a los profesionales de la salud afiliados para
            realizar proyectos personales.
          </HomeP>
          <br />
          <br />
          <HomeButton to="/#nosotros">Saber más</HomeButton>
        </HomeTextContainer>
      </Home>
      <Grid.Bounds
        direction="vertical"
        wrap
        wide={{direction: 'horizontal'}}
        id="nosotros"
        style={{paddingTop: '100px'}}
      >
        <UsBox>
          <UsCard>
            <UsH1>Nosotros</UsH1>
            <UsText>
              Somos un grupo de profesionales que ofrece todo tipo de
              consultoria empresarial y soluciones a las exigencias del mercado
              para fortalecer tu marca y dejar huella en el cliente final.
            </UsText>
          </UsCard>
        </UsBox>
        <UsBox>
          <UsCard>
            <UsH1>Misión</UsH1>
            <UsText>
              “Impactar en el negocio, compartir el riesgo y ofrecer soluciones
              innovadoras para el cliente”
            </UsText>
          </UsCard>
        </UsBox>
        <UsBox>
          <UsCard>
            <UsH1>Visión</UsH1>
            <UsText>
              Brindar cualquier solución o recurso y así lograr resultados
              sobresalientes.{' '}
            </UsText>
            <UsText>
              Aseguramos los resultados de tu proyecto con nuestro{' '}
              <b>“Plan de Continuidad Comercial”</b>
            </UsText>
          </UsCard>
        </UsBox>
      </Grid.Bounds>
      <LazyLoad height="100vh" once>
        <Section id="servicios">
          <H1>Nuestros servicios</H1>
          <Grid.Bounds
            direction="vertical"
            wrap
            tablet={{direction: 'horizontal'}}
            wide={{direction: 'horizontal'}}
            style={{margin: '30px 0 50px 0'}}
          >
            <ServicesBox
              style={{
                background: `url(${services1}) no-repeat center center`,
              }}
              onClick={() => scrollToRef(medicalAffairs)}
            >
              <H1 style={{fontSize: '35px', color: '#ffffff', zIndex: 1}}>
                Medical affairs
              </H1>
              <ServicesBg />
            </ServicesBox>
            <ServicesBox
              style={{
                background: `url(${services2}) no-repeat center center`,
              }}
              onClick={() => scrollToRef(marketing)}
            >
              <H1 style={{fontSize: '35px', color: '#ffffff', zIndex: 1}}>
                Marketing
              </H1>
              <ServicesBg />
            </ServicesBox>
            <ServicesBox
              style={{
                background: `url(${services3}) no-repeat center center`,
              }}
              onClick={() => scrollToRef(marketAccess)}
            >
              <H1 style={{fontSize: '35px', color: '#ffffff', zIndex: 1}}>
                Market access
              </H1>
              <ServicesBg />
            </ServicesBox>
            <ServicesBox
              style={{
                background: `url(${services4}) no-repeat center center`,
              }}
              onClick={() => scrollToRef(other)}
            >
              <H1 style={{fontSize: '35px', color: '#ffffff', zIndex: 1}}>
                Otros
              </H1>
              <ServicesBg />
            </ServicesBox>
          </Grid.Bounds>
          <H2 ref={medicalAffairs}>Medical affairs</H2>
          <Grid.Bounds
            wrap
            style={{
              paddingBottom: '30px',
              width: '100%',
            }}
            direction="horizontal"
            wide={{direction: 'horizontal'}}
          >
            <Grid.Box
              mobile={{width: '100%'}}
              tablet={{width: '100%'}}
              wide={{width: '30%'}}
              fluid
            >
              <Grid.Bounds
                wrap
                direction="horizontal"
                wide={{direction: 'vertical'}}
                style={{width: '100%', height: '100%'}}
              >
                <MedicalAffairsTab
                  style={{borderTop: '2px solid #ebebeb'}}
                  className={selectedMedicalAffairs === 0 ? 'active' : ''}
                  onClick={() => setSelectedMedicalAffairs(0)}
                >
                  <span>Estudios clínicos</span>
                </MedicalAffairsTab>
                <MedicalAffairsTab
                  className={selectedMedicalAffairs === 1 ? 'active' : ''}
                  onClick={() => setSelectedMedicalAffairs(1)}
                >
                  <span>Bioestadística</span>
                </MedicalAffairsTab>
                <MedicalAffairsTab
                  className={selectedMedicalAffairs === 2 ? 'active' : ''}
                  onClick={() => setSelectedMedicalAffairs(2)}
                >
                  <span>Estrategias</span>
                </MedicalAffairsTab>
                <MedicalAffairsTab
                  className={selectedMedicalAffairs === 3 ? 'active' : ''}
                  onClick={() => setSelectedMedicalAffairs(3)}
                >
                  <span>Mapeo de Stakeholders</span>
                </MedicalAffairsTab>
                <MedicalAffairsTab
                  className={selectedMedicalAffairs === 4 ? 'active' : ''}
                  onClick={() => setSelectedMedicalAffairs(4)}
                >
                  <span>Consultoría</span>
                </MedicalAffairsTab>
                <MedicalAffairsTab
                  className={selectedMedicalAffairs === 5 ? 'active' : ''}
                  onClick={() => setSelectedMedicalAffairs(5)}
                >
                  <span>Educación Médica Continua</span>
                </MedicalAffairsTab>
              </Grid.Bounds>
            </Grid.Box>
            <Grid.Box
              mobile={{width: '100%'}}
              tablet={{width: '100%'}}
              wide={{width: '70%'}}
              fluid
              style={{overflow: 'hidden', position: 'relative'}}
            >
              <ReactCSSTransitionGroup
                transitionName="medical-affairs"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}
              >
                <div
                  key={selectedMedicalAffairs}
                  className="medical-affairs-container"
                >
                  <Grid.Bounds
                    wrap
                    direction="horizontal"
                    style={{
                      width: '100%',
                      height: '100%',
                      padding: '20px',
                      border: '2px solid #EBEBEB',
                    }}
                  >
                    <Grid.Box
                      mobile={{hidden: true}}
                      tablet={{hidden: true}}
                      wide={{width: '45%'}}
                      fluid
                      style={{position: 'relative'}}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                        }}
                      >
                        <LazyLoad once height="100%">
                          <img
                            src={
                              medicalAffairsData[selectedMedicalAffairs].image
                            }
                            style={{
                              width: '125%',
                              marginLeft: '-10%',
                              maxWidth: 'unset',
                              alignSelf: 'center',
                            }}
                            alt={`${medicalAffairsData[selectedMedicalAffairs].titleH1
                              } ${medicalAffairsData[selectedMedicalAffairs].titleH4
                              }`}
                          />
                        </LazyLoad>
                      </div>
                    </Grid.Box>
                    <Grid.Box
                      mobile={{width: '100%'}}
                      tablet={{width: '100%'}}
                      wide={{hidden: true}}
                      style={{position: 'relative', textAlign: 'center'}}
                    >
                      <LazyLoad once height="100%">
                        <img
                          src={medicalAffairsData[selectedMedicalAffairs].image}
                          style={{
                            width: '50%',
                          }}
                          alt={`${medicalAffairsData[selectedMedicalAffairs].titleH1
                            } ${medicalAffairsData[selectedMedicalAffairs].titleH4
                            }`}
                        />
                      </LazyLoad>
                    </Grid.Box>
                    <Grid.Box
                      mobile={{width: '100%'}}
                      tablet={{width: '100%'}}
                      wide={{width: '55%'}}
                      style={{paddingTop: '40px'}}
                    >
                      <ServicesOtherH4>
                        {medicalAffairsData[selectedMedicalAffairs].titleH4}
                      </ServicesOtherH4>
                      <ServicesOtherH1>
                        {medicalAffairsData[selectedMedicalAffairs].titleH1}
                      </ServicesOtherH1>
                      <div
                        style={{
                          width: '100%',
                          boxSizing: 'border-box',
                          paddingLeft: '15%',
                        }}
                      >
                        {medicalAffairsData[selectedMedicalAffairs].content()}
                      </div>
                    </Grid.Box>
                  </Grid.Bounds>
                </div>
              </ReactCSSTransitionGroup>
            </Grid.Box>
          </Grid.Bounds>
          <H2 ref={marketing}>Marketing</H2>
          <Grid.Bounds
            wrap
            style={{
              paddingBottom: '30px',
            }}
            direction="vertical"
            wide={{direction: 'horizontal'}}
          >
            <Grid.Bounds wrap direction="horizontal" style={{width: '100%'}}>
              <MarketingTab
                style={{borderLeft: '2px solid #ebebeb'}}
                className={selectedMarketing === 0 ? 'active' : ''}
                onClick={() => setSelectedMarketing(0)}
              >
                <span>Investigaciones de Mercado</span>
              </MarketingTab>
              <MarketingTab
                className={selectedMarketing === 1 ? 'active' : ''}
                onClick={() => setSelectedMarketing(1)}
              >
                <span>Marketing Plan</span>
              </MarketingTab>
              <MarketingTab
                className={selectedMarketing === 2 ? 'active' : ''}
                onClick={() => setSelectedMarketing(2)}
              >
                <span>Marketing Digital</span>
              </MarketingTab>
              <MarketingTab
                className={selectedMarketing === 3 ? 'active' : ''}
                onClick={() => setSelectedMarketing(3)}
              >
                <span>Publicidad</span>
              </MarketingTab>
              <MarketingTab
                className={selectedMarketing === 4 ? 'active' : ''}
                onClick={() => setSelectedMarketing(4)}
              >
                <span>Desarrollo y Gestión de Contenidos</span>
              </MarketingTab>
              <MarketingTab
                className={selectedMarketing === 5 ? 'active' : ''}
                onClick={() => setSelectedMarketing(5)}
              >
                <span>Fuerza de Ventas</span>
              </MarketingTab>
            </Grid.Bounds>
            <Grid.Bounds
              wrap
              direction="horizontal"
              style={{
                width: '100%',
                padding: '20px',
                border: '2px solid #EBEBEB',
                borderTop: 'none',
                overflow: 'hidden',
              }}
              wide={{maxHeight: '500px'}}
            >
              <ReactCSSTransitionGroup
                transitionName="marketing"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}
                style={{width: '100%', maxHeight: '500px'}}
              >
                <div key={selectedMarketing} className="marketing-container">
                  <Grid.Box
                    mobile={{width: '100%'}}
                    tablet={{width: '100%'}}
                    wide={{hidden: true}}
                    style={{position: 'relative', textAlign: 'center'}}
                  >
                    <img
                      src={marketingData[selectedMarketing].image}
                      style={{
                        width: '90%',
                      }}
                      alt={`${marketingData[selectedMarketing].titleH1} ${marketingData[selectedMarketing].titleH4
                        }`}
                    />
                  </Grid.Box>
                  <Grid.Box
                    mobile={{width: '100%'}}
                    tablet={{width: '100%'}}
                    wide={{width: '65%'}}
                    style={{paddingTop: '40px'}}
                  >
                    <ServicesOtherH4>
                      {marketingData[selectedMarketing].titleH4}
                    </ServicesOtherH4>
                    <ServicesOtherH1>
                      {marketingData[selectedMarketing].titleH1}
                    </ServicesOtherH1>
                    {marketingData[selectedMarketing].content()}
                  </Grid.Box>
                  <Grid.Box
                    mobile={{hidden: true}}
                    tablet={{hidden: true}}
                    wide={{width: '35%'}}
                    style={{position: 'relative'}}
                  >
                    <img
                      src={marketingData[selectedMarketing].image}
                      style={{
                        width: '120%',
                        maxWidth: 'unset',
                        position: 'absolute',
                        top: '50%',
                        left: '-10%',
                        transform: 'translateY(-50%)',
                      }}
                      alt={`${marketingData[selectedMarketing].titleH1} ${marketingData[selectedMarketing].titleH4
                        }`}
                    />
                  </Grid.Box>
                </div>
              </ReactCSSTransitionGroup>
            </Grid.Bounds>
          </Grid.Bounds>
          <H2 ref={marketAccess}>Market access</H2>
          <Grid.Bounds
            wrap
            style={{
              background: '#252525',
              color: '#ffffff',
              padding: '0 5%',
              paddingBottom: '30px',
            }}
            direction="vertical"
            wide={{direction: 'horizontal'}}
          >
            <Grid.Box
              mobile={{width: '100%'}}
              tablet={{width: '100%'}}
              wide={{width: '50%'}}
            >
              <Grid.Bounds
                wrap
                direction="vertical"
                wide={{direction: 'horizontal'}}
              >
                <MarketAccessImg src={care} />
                <Grid.Box
                  mobile={{width: '100%'}}
                  tablet={{width: '100%'}}
                  wide={{width: '80%'}}
                  style={{paddingLeft: '20px'}}
                >
                  <MarketAccessTitle>Salud pública</MarketAccessTitle>
                  <MarketAccessText>Publicaciones posturales</MarketAccessText>
                  <MarketAccessText>
                    Para impactar los sistemas de salud, publicaremos las
                    opiniones de expertos regionales con una revisión intensiva
                    de la literatura, publicando un documento que resuelva el
                    mejor resultado para tu producto o servicio.
                  </MarketAccessText>
                  <MarketAccessText>
                    Nuestra estrategia de reclutamiento ha sido vital para la
                    adición exitosa de valiosos líderes de opinión o antiguos
                    responsables de la toma de decisiones de nuestro proyectos.
                  </MarketAccessText>
                </Grid.Box>
              </Grid.Bounds>
            </Grid.Box>
            <Grid.Box
              mobile={{width: '100%'}}
              tablet={{width: '100%'}}
              wide={{width: '50%'}}
            >
              <Grid.Bounds
                wrap
                direction="vertical"
                wide={{direction: 'horizontal'}}
              >
                <MarketAccessImg src={presentation} />
                <Grid.Box
                  mobile={{width: '100%'}}
                  tablet={{width: '100%'}}
                  wide={{width: '80%'}}
                  style={{paddingLeft: '20px'}}
                >
                  <MarketAccessTitle>Health Economics</MarketAccessTitle>
                  <MarketAccessText>
                    Preparamos presentaciones para la evaluación de medicamentos
                    y dispositivos a las autoridades de inversión en todo el
                    mundo.
                  </MarketAccessText>
                  <MarketAccessText>
                    Esto incluye recopilar y sintetizar todas las publicaciones
                    relevantes, datos, evaluaciones farmacoeconómicas y
                    resúmenes clínicos en el formato preferido, así como
                    completar todos los formularios específicos de envío.
                  </MarketAccessText>
                  <MarketAccessText>
                    Para dispositivos médicos y productos vendidos en
                    hospitales, se realizan presentaciones adaptadas a
                    hospitales y otros responsables de la toma de decisiones.
                  </MarketAccessText>
                </Grid.Box>
              </Grid.Bounds>
            </Grid.Box>
            <Grid.Box
              mobile={{width: '100%'}}
              tablet={{width: '100%'}}
              wide={{width: '50%'}}
            >
              <Grid.Bounds
                wrap
                direction="vertical"
                wide={{direction: 'horizontal'}}
              >
                <MarketAccessImg src={money} />
                <Grid.Box
                  mobile={{width: '100%'}}
                  tablet={{width: '100%'}}
                  wide={{width: '80%'}}
                  style={{paddingLeft: '20px'}}
                >
                  <MarketAccessTitle>Health Economics</MarketAccessTitle>
                  <MarketAccessText>
                    Impacto presupuestario, análisis de creación y adopción de
                    modelos de costo- beneficio
                  </MarketAccessText>
                  <MarketAccessText style={{marginTop: '20px'}}>
                    Construiremos los mejores modelos de impacto presupuestario.
                    Además, adaptamos modelos existentes a diferentes países y
                    regiones. Se pueden adaptar individualmente a una
                    subpoblación específica o sistema de salud basado en las
                    necesidades del cliente.
                  </MarketAccessText>
                </Grid.Box>
              </Grid.Bounds>
            </Grid.Box>
            <Grid.Box
              mobile={{width: '100%'}}
              tablet={{width: '100%'}}
              wide={{width: '50%'}}
            >
              <Grid.Bounds
                wrap
                direction="vertical"
                wide={{direction: 'horizontal'}}
              >
                <MarketAccessImg src={openBook} />
                <Grid.Box
                  mobile={{width: '100%'}}
                  tablet={{width: '100%'}}
                  wide={{width: '80%'}}
                  style={{paddingLeft: '20px'}}
                >
                  <MarketAccessTitle>Soporte Literario</MarketAccessTitle>
                  <MarketAccessText>Revisión de literatura</MarketAccessText>
                  <MarketAccessText>
                    El verdadero proceso central para hacer un impacto real
                    haciendo extensas revisiones de literatura que se puede
                    llevar a cabo para un país en específico, bases de datos de
                    literatura aunado a las bases de datos de literatura médica
                    estándar.
                  </MarketAccessText>
                </Grid.Box>
              </Grid.Bounds>
            </Grid.Box>
          </Grid.Bounds>
          <H2 ref={other}>Otros</H2>
          <Grid.Bounds direction="vertical" wide={{direction: 'horizontal'}}>
            <ServicesOtherImage src={whistle} />
            <ServicesOtherContent
              h4="Entrenamiento y"
              h1="Capacitación"
              text={[
                'Donde sea que necesites ser reclutado',
                'Contamos con entrenadores reales',
                'Garantizamos un aumento del índice de conocimiento',
                'En el sitio (fuerza de ventas, HCP, pacientes)',
                'En línea Congresos',
              ]}
            />
          </Grid.Bounds>
          <Grid.Bounds direction="vertical" wide={{direction: 'horizontal'}}>
            <ServicesOtherContent
              hidden
              h4="Organización de"
              h1="Viajes"
              text={[
                'Llevaremos a sus key stakeholders',
                'Nuestra metodología cumple con directrices locales, directrices internacionales, con regulaciones y leyes locales e internacionales.',
                'En grandes grupos le enviaremos un agente para ayudar a su huésped tenemos alianzas estratégicas con varias agencias de viajes.',
              ]}
            />
            <ServicesOtherImage src={airplaneChairs} />
            <ServicesOtherContent
              show
              h4="Organización de"
              h1="Viajes"
              text={[
                'Llevaremos a sus key stakeholders',
                'Nuestra metodología cumple con directrices locales, directrices internacionales, con regulaciones y leyes locales e internacionales.',
                'En grandes grupos le enviaremos un agente para ayudar a su huésped tenemos alianzas estratégicas con varias agencias de viajes.',
              ]}
            />
          </Grid.Bounds>
          <Grid.Bounds direction="vertical" wide={{direction: 'horizontal'}}>
            <ServicesOtherImage src={projectorScreen} />
            <ServicesOtherContent
              h4="Gestión de"
              h1="Proyectos"
              text={[
                'PMO',
                'Haremos todo por ti',
                'Podemos designar una PMO de acuerdo con sus necesidades',
                'Nos preocupa el éxito de su proyecto',
              ]}
            />
          </Grid.Bounds>
        </Section>
      </LazyLoad>
      <LazyLoad height="100vh" once>
        <Section id="contacto">
          <ServicesOtherH4 style={{textAlign: 'center'}}>
            ¿Tienes alguna duda?
          </ServicesOtherH4>
          <ServicesOtherH1 style={{textAlign: 'center'}}>
            Nos encantaría poder responderla
          </ServicesOtherH1>
          <ServicesOtherH4 style={{textAlign: 'center'}}>
            Escríbela y nos pondremos en contacto lo antes posible
          </ServicesOtherH4>
          <form
            action="https://getform.io/f/e875a3a7-2a9c-4a55-a0ea-f8dcde1790ef"
            method="POST"
            style={{paddingTop: '50px'}}
          >
            <ContactLabel>Nombre</ContactLabel>
            <ContactInput type="text" name="nombre" />
            <ContactLabel>E-Mail</ContactLabel>
            <ContactInput type="email" name="email" />
            <ContactLabel>Teléfono</ContactLabel>
            <ContactInput type="text" name="telefono" />
            <ContactLabel>Mensaje</ContactLabel>
            <ContactInput
              as="textarea"
              name="mensaje"
              style={{height: '220px', padding: '10px', resize: 'none'}}
            />
            <div style={{textAlign: 'center'}}>
              <ContactButton type="submit">Enviar</ContactButton>
            </div>
          </form>
        </Section>
      </LazyLoad>
    </Layout>
  )
}

export default IndexPage
