/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import Grid from 'hedron'

import Header from './header'
import logoWhite from '../images/logo-white.png'
import downloadFile from '../../static/Aviso de privacidad EDUCAMED.pdf'

import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Grid.Provider
        breakpoints={{ mobile: '-500', tablet: '501-850', wide: '+850' }}
      >
        <Grid.Bounds direction="vertical">
          <Header siteTitle={data.site.siteMetadata.title} />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 1080,
              width: '100%',
            }}
          >
            <main>{children}</main>
            <footer className="footer">
              <img src={logoWhite} alt="Logo educamed" />
              <hr />
              <div className="link-container">
                <a href={downloadFile} download>
                  Aviso de Privacidad
                </a>
              </div>
              Derechos reservados © {new Date().getFullYear()} EDUCAMED
            </footer>
          </div>
        </Grid.Bounds>
      </Grid.Provider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
